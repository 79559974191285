<template>
  <b-card
    no-body
  >
    <b-card-body>
      <div class="mb-1">
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Fecha"
              label-for="Fecha"
            >
              <flat-pickr 
                class="form-control date-picker-input"
                :config="datePickerConfig"
                @on-change="changeDate"
                :disabled="isDisabledDate"
                v-model="dateSelected">
              </flat-pickr>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Canal"
              label-for="Canal"
            >
              <v-select
                v-model="filterCanal"
                :options="canalOptions"
                :reduce="val => val.value"
                input-id="Canal"
                class="date-picker-input"
                :disabled = "isDisabledCanal"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>

      <div
        v-if="isLoading"
        class="my-3"
      >
        <custom-loading></custom-loading>
      </div>
      <div
        v-else
      >
        <Pivot
          :report="report"
          toolbar>
        </Pivot>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { 
  BCard, BRow, BCol, BCardBody, BFormGroup,
} from 'bootstrap-vue'
import { ref, getCurrentInstance, watch } from 'vue'
import VGrid from '@revolist/vue-datagrid';
import axios from '@axios'
import CustomLoading from '@/modules/common/CustomLoading.vue'
import flatPickr from 'vue-flatpickr-component'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import { Spanish } from "flatpickr/dist/l10n/es.js"
import { required } from '@validations'

import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import gql from 'graphql-tag'

export default {
  components: {
    BCard,
    BRow, 
    BCol,
    BCardBody,
    BFormGroup,

    vSelect,
    CustomLoading,
    flatPickr,

    VGrid,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
    }
  },
  setup() {
    const source = ref([])
    const pinnedBottomRows = ref([])
    const columns = ref([])
    const pinnedTopRows = ref([])
    const isLoading = ref(true)
    const report = ref('')
    const dateSelected = ref('')
    const canalOptions = ref([])
    const filterCanal = ref('')
    const isDisabledCanal = ref('false')
    const isDisabledDate = ref('false')

    const vm = getCurrentInstance().proxy

    const datePickerConfig = {
      locale: Spanish,
      altInput: true,
      altFormat: 'M Y',
      plugins: [
        new monthSelectPlugin({
          shorthand: true,
          dateFormat: 'm-Y',
          // theme: 'dark',
        })
      ]
    }

    vm.$apollo.addSmartSubscription('getFiltersDataCamal', {
      query: gql`
        subscription getFiltersDataCamal {
          canalOptions: db_main_curr_month(distinct_on: canal) {
            label: canal
            value: canal
          }
        }
      `,
      result ({data}) {
        canalOptions.value = data.canalOptions
      },
    })

    const setCurrentMonth = () => {
      let dateObj = new Date();
      let monthNumber = dateObj.getUTCMonth() + 1; //months from 1-12
      let month = monthNumber < 10 ? "0" + monthNumber : monthNumber
      let year = dateObj.getUTCFullYear();

      dateSelected.value = month + "-" + year
    }

    const fetchData = async (monthYear) => {
      try {
        isLoading.value = true
        isDisabledCanal.value = true
        isDisabledDate.value = true

        if (!monthYear) monthYear = dateSelected.value
        const month = monthYear.split('-')[0]
        const year = monthYear.split('-')[1]
        const id = year + month

        let dataCanal = filterCanal.value == null || filterCanal.value.length == 0 ? [] : [filterCanal.value]

        const result = await axios
        .post(`${process.env.VUE_APP_BASE_URL}/prepare_summary`,{
          filters: {
            id: [id],
            canal: dataCanal
          }
        })
        
        // console.log(result.data.result)

        report.value = {
          "dataSource": {
            "data": result.data.result.rows
          },
          "slice": {
            "rows": [
              {
                "uniqueName": "input"
              },
              {
                "uniqueName": "BPU"
              },
              {
                "uniqueName": "nart"
              }
            ],
            "columns": [
              {
                "uniqueName": "year"
              },
              {
                "uniqueName": "mes"
              },
              {
                "uniqueName": "Measures"
              }
            ],
            "measures": [
              {
                "uniqueName": "netsales",
                "aggregation": "sum"
              },
              {
                "uniqueName": "units",
                "aggregation": "sum"
              }
            ],
          },
          "formats": [
            {
              "name": "",
              "thousandsSeparator": " ",
              "decimalSeparator": ".",
              "decimalPlaces": 0,
              "currencySymbol": "",
              "currencySymbolAlign": "right",
              "nullValue": "",
              "textAlign": "right",
              "isPercent": false
            }
          ]
        }
      } catch(e) {
        console.log(e)
      } finally {
        isLoading.value = false
        isDisabledCanal.value = false
        isDisabledDate.value = false
      }
    }

    const changeDate = (selectedDates, dateStr, instance) => {
      fetchData(dateStr)
    }

    watch(filterCanal, (newValue, oldValue) => {
      fetchData()
    })

    setCurrentMonth()

    fetchData()

    return {
      source,
      pinnedBottomRows,
      columns,
      pinnedTopRows,
      isLoading,
      report,
      datePickerConfig,
      dateSelected,
      canalOptions,
      filterCanal,
      isDisabledCanal,
      isDisabledDate,

      changeDate,
    }
  }
}
</script>

<style lang="scss">
.date-picker-input {
  max-width: 15rem;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>